import React, { useState } from "react";
import CustomNavLink from "./CustomNavLink";
import CustomRangePicker from "../../../components/RangePicker";
import MenuPopover from "./MenuPopover";
import MenuButton from "./MenuButton";
import { Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const NavButton = ({
  id,
  route,
  onClick,
  name,
  icon,
  isActive,
  isNavButton,
  arrowIcon,
  label,
  hasDivider,
  openOverlay,
  isSubNav,
  background,
  isSwitch,
  isDatePicker,
  handleMonthChange,
  selectedMonthRange,
  subItems,
  disabled,
  isPopover,
  mode,
}) => {
  const helpButton = name === "Besoin d’aide ?";
  const modeButton = name === "Trésorerie";

  const { isSidebarClosed } = useSelector((state) => state.layout);

  const [helpHovered, setHelpHovered] = useState(false);
  const location = useLocation();

  let buttonClassName = "button nav-link";
  if (helpButton) {
    buttonClassName += " config-buttons";
  } else {
    buttonClassName += disabled ? " disabled" : "";
  }

  let buttonName;
  if (name === "Afficher la synthèse") {
    if (mode === "compta") {
      buttonName = "Afficher une synthèse du résultat ";
    } else {
      buttonName = "Afficher une synthèse de trésorerie";
    }
  } else {
    buttonName = name;
  }

  let title;

  if (helpHovered && helpButton) {
    title = isSidebarClosed ? (
      <div>
        Besoin d'aide:
        <br />
        En cours de construction ! N'hésitez pas à nous contacter via:
        tresovista@tresovista.com
      </div>
    ) : (
      <div>
        En cours de construction ! N'hésitez pas à nous contacter via:
        tresovista@tresovista.com
      </div>
    );
  } else if (name === "Afficher la synthèse") {
    if (location.pathname.includes("listing")) {
      title = `Pour afficher la synthèse, vous devez passer en vue "Catégories"`;
    } else {
      title = isSidebarClosed ? (
        <div>
          Afficher une synthèse{" "}
          {mode === "compta" ? "du résultat " : "de trésorerie"}
        </div>
      ) : null;
    }
  } else if (name === "Afficher un budget prev.") {
    if (location.pathname.includes("listing")) {
      title = `Pour afficher un budget prévisionnel, vous devez passer en mode
            "Résultat" ET en vue "Catégories".`;
    } else if (true) {
      if (mode === "treso") {
        title = (
          <div>
            Pour afficher un budget prévisionnel, vous devez passer en mode
            "Résultat" ET en vue "Catégories".
          </div>
        );
      }
      if (mode === "compta") {
        title = <div>Afficher un budget prev:</div>;
      }
    }
  } else {
    title = isSidebarClosed ? (
      <div>
        Afficher un budget prev:
        <br />
        Pour afficher un budget prévisionnel vous devez passer en mode
        "Résultat" ET en vue "Catégories"
      </div>
    ) : (
      <div>
        Pour afficher un budget prévisionnel vous devez passer en mode
        "Résultat" ET en vue "Catégories"
      </div>
    );
  }

  let dividerClassName;

  if (mode === "treso") {
    dividerClassName = "treso-divider";
  } else {
    dividerClassName = "compta-divider";
  }

  const check =
    (helpHovered && helpButton) ||
    (helpHovered && name === "Afficher la synthèse") ||
    (helpHovered && name === "Afficher un budget prev.") ||
    (helpHovered && mode !== "compta" && name === "Afficher un budget prev.");

  return isNavButton ? (
    <CustomNavLink
      key={id}
      route={route}
      name={name}
      label={label}
      openOverlay={openOverlay}
      arrowIcon={arrowIcon}
      icon={icon}
      isActive={isActive}
      onClick={onClick}
      background={background}
      isSwitch={isSwitch}
      subItems={subItems}
      isPopover={isPopover}
      modeButton={modeButton}
    />
  ) : isPopover ? (
    <MenuPopover
      isPopover={isPopover}
      isActive={isActive}
      isSubNav={isSubNav}
      icon={icon}
      label={label}
      background={background}
      name={name}
      subItems={subItems}
    />
  ) : isSubNav ? (
    <>
      <CustomNavLink
        name={name}
        label={label}
        icon={icon}
        isActive={isActive}
        route={route}
        onClick={onClick}
        background={background}
        isSubNav={isSubNav}
        className={` button nav-link `}
        isSwitch={isSwitch}
      />
    </>
  ) : isDatePicker ? (
    <>
      <Tooltip
        trigger="hover"
        mouseLeaveDelay={0}
        title={isSidebarClosed ? "Période affichée" : null}
      >
        <CustomRangePicker
          selectedMonthRange={selectedMonthRange}
          handleMonthChange={handleMonthChange}
          className={"button nav-link custom-range-picker"}
          hasDivider={hasDivider}
          label="Période Affichée"
          index={1}
        />
        {hasDivider && (
          <div
            className={`
          divider ${dividerClassName} `}
          ></div>
        )}
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip
        visible={check && title}
        mouseLeaveDelay={0}
        trigger="hover"
        title={title}
      >
        <MenuButton
          key={id}
          name={buttonName}
          handleSubmit={(e) => {
            onClick(name);
            e.preventDefault();
          }}
          onHoveringOut={() => setHelpHovered(false)}
          onHoverIn={() => setHelpHovered(true)}
          className={buttonClassName}
          primaryIcon={icon}
          secondaryIcon={arrowIcon}
        />
      </Tooltip>
      {hasDivider && <div className={`divider ${dividerClassName}`}></div>}
    </>
  );
};
export default NavButton;
