import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DuplicateWritingModal,
  AddWritingModal,
  VerifyClosing,
  DeteteAccount,
  ModifyBudget,
  OpenBudget,
  CreateCategoryCheck,
  DeleteCategoryCheck,
  DndCheck,
  ModifySerie,
} from "../Modals";

import { closeModal } from "../../data/slices/modals";
import AddCompteModal from "../Modals/AddAccountModal";

const ModalsProvider = () => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };

  const handleClose = (id) => {
    dispatch(closeModal(id));
  };

  return (
    <>
      <AddWritingModal
        id="add-writing-expression"
        open={modalState("add-writing-expression", "open")}
        data={modalState("add-writing-expression", "data")}
        handleClose={handleClose}
      />
      <VerifyClosing
        id="verify-closing-modal"
        open={modalState("verify-closing-modal", "open")}
        data={modalState("verify-closing-modal", "data")}
      />
      <DuplicateWritingModal
        id="duplicate-writing-modal"
        open={modalState("duplicate-writing-modal", "open")}
        data={modalState("duplicate-writing-modal", "data")}
        handleClose={handleClose}
      />
      <AddCompteModal
        id="create-compte-modal"
        open={modalState("create-compte-modal", "open")}
        data={modalState("create-compte-modal", "data")}
        handleClose={handleClose}
      />
      <DeteteAccount
        id="delete-compte-modal"
        open={modalState("delete-compte-modal", "open")}
        data={modalState("delete-compte-modal", "data")}
        handleClose={handleClose}
      />
      <ModifyBudget
        id="modify-budget-modal"
        open={modalState("modify-budget-modal", "open")}
        data={modalState("modify-budget-modal", "data")}
        handleClose={handleClose}
      />
      <OpenBudget
        id="open-budget-modal"
        open={modalState("open-budget-modal", "open")}
        data={modalState("open-budget-modal", "data")}
        handleClose={handleClose}
      />
      <CreateCategoryCheck
        id="create-category-check-modal"
        open={modalState("create-category-check-modal", "open")}
        data={modalState("create-category-check-modal", "data")}
        handleClose={handleClose}
      />
      <DeleteCategoryCheck
        id="delete-category-check-modal"
        open={modalState("delete-category-check-modal", "open")}
        data={modalState("delete-category-check-modal", "data")}
        handleClose={handleClose}
      />
      <DndCheck
        id="dnd-category-check-modal"
        open={modalState("dnd-category-check-modal", "open")}
        data={modalState("dnd-category-check-modal", "data")}
        handleClose={handleClose}
      />
      <ModifySerie
        id="modify-serie"
        open={modalState("modify-serie", "open")}
        data={modalState("modify-serie", "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalsProvider;
