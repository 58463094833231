import React from "react";
import exclamation from "../../../assets/img/exclamation.png";
import budget from "../../../assets/img/BUDGET.svg";
import { CloseOutlined } from "@ant-design/icons";

const Title = ({
  marginHeader,
  className,
  projectTitle,
  required,
  subTitle,
  isDelete,
  fontSizeList,
  maxWidthwidth,
  isObjectif,
  hasCloseButton,
  onClose,
  fontSizeUpdate,
}) => {
  return (
    <div
      className={`${className} project-title`}
      style={{ marginLeft: marginHeader, maxWidth: maxWidthwidth }}
    >
      {hasCloseButton && (
        <CloseOutlined
          style={{
            fontSize: "20px",
            position: "absolute",
            top: "1.5rem",
            cursor: "pointer",
            right: "2rem",
            width: "15px",
            height: "15px",
          }}
          onClick={onClose}
        />
      )}
      {isObjectif && !isDelete && (
        <div className="objective-wrapper">
          <img src={budget} alt="objective-icon" />
          <h4 style={{ fontSize: fontSizeUpdate }}>{projectTitle}</h4>
        </div>
      )}
      {!isObjectif && !isDelete && (
        <h4 className={isDelete ? "delete-h4" : ""}>{projectTitle}</h4>
      )}
      {required ? (
        <>
          <span>
            <br /> {subTitle}
          </span>
        </>
      ) : isDelete ? (
        <div className="element-centred">
          <img
            src={exclamation}
            alt="exclamation"
            style={{ marginRight: "11px" }}
          />
          <span id="confirm-delete">{subTitle}</span>
        </div>
      ) : (
        <span style={{ fontSize: fontSizeList }}> {subTitle} </span>
      )}

      {isDelete && (
        <h1 className={isDelete ? "delete-text" : ""}>{projectTitle}</h1>
      )}
    </div>
  );
};

export default Title;
