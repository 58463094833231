import React from "react";
import "./_overlay.scss";
const Overlay = ({ children, show }) => {
  const overlayStyles = {
    display: show ? "flex" : "none",
  };

  return (
    <div style={overlayStyles} className="overlay">
      {children}
    </div>
  );
};

export default Overlay;
