import React from "react";
import Button from "../../Button";
import Title from "../../Project/Title";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Trash from "../../../assets/img/Garbage.svg";

function DeteteAccount({ open, data }) {
  const handleCloseFunc = () => {
    data.onClose();
  };


  return (
    <Overlay show={open} closable={true} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={"Êtes-vous sûr de vouloir supprimer ce compte?"}
          subTitle={`Supprimer ${data?.name}`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Non"}
            onClick={() => data.abandon()}
            className={"action-button"}
          />
          <Button
            text={"Oui"}
            onClick={() => handleCloseFunc()}
            className={"action-button agree-action-button"}
            icon={"empty"}
            RigthIcon={Trash}
            isRigthIcon
            iconClassName={"delete-icon"}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default DeteteAccount;
