import React from "react";
import { useDispatch } from "react-redux";
import { openDeleteDialog } from "../../../data/slices/table";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

function DeleteCategoryCheck({ open, id, handleClose, data }) {
  const dispatch = useDispatch();

  const handleCloseFunc = (id) => {
    handleClose(id);
    dispatch(openDeleteDialog(null));
  };

  return (
    <Overlay show={open} closable={true} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={
            <div style={{ width: "95%" }}>
              Cette modification de l’organisation de vos catégories engendrera
              des modifications sur vos budgets prévisionnels enregistrés et
              pourra y générer des alertes.
            </div>
          }
          subTitle={`Vous allez supprimer une catégorie`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Annuler"}
            onClick={() => handleCloseFunc(id)}
            className={"action-button"}
          />
          <Button
            text={"Continuer"}
            onClick={() => data.action()}
            className={"action-button agree-action-button"}
            iconClassName={"delete-icon"}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default DeleteCategoryCheck;
