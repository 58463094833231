function addObjectiveToCategory(categories, objectifs) {
  try {
    let doesAnyOflevelOnenotMentioned = false;
    let sumOfAutomaticValues = 0;

    const addObjective = (category, objectif) => {
      if (category.isRacine) return false;

      if (Number(category.id) === Number(objectif.categoryId)) {
        category.objectif = objectif;
        return true;
      }

      if (category.children) {
        for (const child of category.children) {
          if (addObjective(child, objectif)) {
            return true;
          }
        }
      }
      return false;
    };

    objectifs.forEach((objectif) => {
      for (const category of categories) {
        if (addObjective(category, objectif)) {
          break;
        }
      }
    });

    const updateCategories = (categories) => {
      return categories.map((category) => {
        if (category.isRacine) return category;

        const updatedCategory = { ...category };

        if (updatedCategory.children) {
          const updatedChildren = updateCategories(updatedCategory.children);

          const doesAnyOfTheChildrenHasObjective = updatedChildren.some(
            (child) => child.objectif && child.objectif?.amount != null
          );

          const AnyChildHasAutomaticValue = updatedChildren.some(
            (child) => child.hasAutomaticValue
          );

          const hasObjectif =
            !!category.objectif && updatedCategory?.objectif?.amount;

          const totalChildrenValue = updatedChildren.reduce((sum, child) => {
            return (
              sum +
              (child.objectif && child.objectif.amount
                ? parseFloat(child.objectif.amount)
                : 0)
            );
          }, 0);

          if (updatedCategory.levelId === 1) {
            const AnyCategoryOf1stHasChild = categories.some((category) => {
              return category.objectif && category.levelId === 1;
            });

            if (
              AnyCategoryOf1stHasChild &&
              !AnyChildHasAutomaticValue &&
              !hasObjectif
            ) {
              updatedCategory.notMentioned = true;
              doesAnyOflevelOnenotMentioned = true;
            }
          }

          if (updatedCategory.levelId !== 1) {
            const anychildHasObjectif = categories.some((category) => {
              return category.objectif && category.objectif.amount != null;
            });

            if (anychildHasObjectif && !hasObjectif) {
              updatedCategory.notMentioned = true;
            }
          }

          updatedCategory.children = updatedChildren;

          updatedCategory.hasAutomaticValue =
            doesAnyOfTheChildrenHasObjective && !hasObjectif;

          updatedCategory.automaticValue = totalChildrenValue;

          if (AnyChildHasAutomaticValue && !hasObjectif) {
            updatedCategory.notMentioned = false;
            updatedCategory.hasAutomaticValue = true;
          }

          if (updatedCategory.hasAutomaticValue) {
            updatedCategory.notMentioned = false;
          }

          const sumOfAutomaticChildren = updatedChildren.reduce(
            (acc, child) => {
              if (child.hasAutomaticValue && child.automaticValue) {
                acc += child.automaticValue;
              }
              return acc;
            },
            0
          );

          updatedCategory.automaticValue =
            updatedCategory.automaticValue + sumOfAutomaticChildren;

          const hasValueMismatch =
            hasObjectif &&
            updatedCategory.children.length > 0 &&
            doesAnyOfTheChildrenHasObjective &&
            parseFloat(category.objectif.amount) !==
              totalChildrenValue + sumOfAutomaticChildren;

          if (hasValueMismatch) {
            let difference = category.objectif.amount
              ? parseFloat(category.objectif.amount) -
                totalChildrenValue -
                sumOfAutomaticChildren
              : -totalChildrenValue + sumOfAutomaticChildren;

            if (difference !== 0) {
              const existingRacineCategory = updatedChildren.find(
                (child) => child.isRacine === true
              );

              if (existingRacineCategory) {
                existingRacineCategory.objectif = {
                  amount: difference.toFixed(2),
                };
              } else {
                const racineCategory = {
                  id: `racine-${category.id}`,
                  name: `Racine de ${category.name}`,
                  categoryId: category.id,
                  isSpecial: category?.isSpecial,
                  isRacine: true,
                  objectif: {
                    amount: difference.toFixed(2),
                  },
                  levelId: updatedCategory.levelId + 1,
                  children: [],
                };

                updatedCategory.hasAlert = true;

                updatedChildren.unshift(racineCategory);
              }
            }
          }

          if (updatedCategory.hasAutomaticValue) {
            const sumOfDirectChildrenAutomaticValues =
              updatedCategory.automaticValue +
              updatedChildren.reduce((sum, child) => {
                if (child.hasAutomaticValue) {
                  return sum + (child.automaticValue || 0);
                }
                return sum;
              }, 0);

            updatedCategory.automaticValue = sumOfDirectChildrenAutomaticValues;
          }

          updatedCategory.hasAlert =
            updatedChildren.some((child) => child.notMentioned) ||
            hasValueMismatch;

          updatedCategory.children = updatedChildren;
        }

        return updatedCategory;
      });
    };

    const updatedCategoriesWithObjectives = updateCategories(categories);

    const levelOneCategories = updatedCategoriesWithObjectives.filter(
      (category) => category.levelId === 1
    );

    sumOfAutomaticValues = levelOneCategories
      .filter((el) => el?.hasAutomaticValue)
      .reduce((acc, elem) => acc + (elem?.automaticValue || 0), 0);

    const hasAutomaticValueInLevelOne = levelOneCategories.some(
      (category) => category.hasAutomaticValue
    );

    const allOthersMentioned = levelOneCategories.every(
      (category) => category.hasAutomaticValue || !category.notMentioned
    );

    if (hasAutomaticValueInLevelOne && allOthersMentioned) {
      doesAnyOflevelOnenotMentioned = false;
    }

    return {
      updatedCategoriesWithObjectives,
      sumOfAutomaticValues,
      doesAnyOflevelOnenotMentioned,
    };
  } catch (error) {
    console.log({ error });
  }
}

export { addObjectiveToCategory };
