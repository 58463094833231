import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  lockunlockObjectif,
  openListObjectiveDialog,
} from "../../../data/slices/objectives";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

function OpenBudget({ open, data, id, handleClose }) {
  const dispatch = useDispatch();

  const { openedObjectif } = useSelector((state) => state.objectives);

  const handleOpenCloseBudget = async (objectiveId) => {
    handleClose(id);

    dispatch(
      lockunlockObjectif({
        openedObjectif: objectiveId,
        isLocked: !openedObjectif.isLocked,
      })
    );
  };

  return (
    <Overlay show={open} closable={true} onClick={() => handleClose(id)}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          hasCloseButton={openedObjectif?.isLocked}
          onClose={() => handleClose(id)}
          projectTitle={
            !openedObjectif?.isLocked
              ? "Vous souhaitez: "
              : "Note: Il peut parfois être judicieux de conserver en l’état un budget déjà enregistré afin d’en conserver l’historique, et d’en créer une version dupliquée sur laquelle vous effectuerez vos modifications."
          }
          subTitle={
            openedObjectif?.isLocked
              ? `Vous souhaitez déverrouiller un budget pour le modifier.`
              : "Verrouiller un budget"
          }
        />
        <div className="verify-inputs-popup">
          {!openedObjectif?.isLocked ? (
            <>
              <Button
                text={"Annuler"}
                className={"action-button-budget"}
                onClick={() => {
                  handleClose(id);
                  handleOpenCloseBudget(data?.objectif?.id);
                }}
              />
              <Button
                text={"Verrouiller le budget"}
                className={"action-button-budget"}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                }}
                onClick={() => {
                  handleClose(id);
                  handleOpenCloseBudget(data?.objectif?.id);
                }}
              />
            </>
          ) : (
            <>
              <Button
                text={"Plutôt aller sur la fenêtre pour dupliquer le budget"}
                className={"action-button-budget"}
                style={{
                  width: "240px",
                  padding: "0 10px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                  height: "42px",
                }}
                onClick={() => {
                  handleClose(id);
                  dispatch(openListObjectiveDialog({ status: "true" }));
                }}
              />
              <Button
                text={"Déverrouiller le budget"}
                className={"action-button-budget"}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  height: "42px",
                  width: "240px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  handleOpenCloseBudget(data?.objectif?.id);
                }}
              />
            </>
          )}
        </div>
        {openedObjectif?.isLocked && (
          <div className="verify-inputs-popup" style={{ marginTop: "30px" }}>
            <Button
              text={"Annuler"}
              className={"action-button-budget"}
              onClick={() => {
                handleClose(id);
              }}
            />
          </div>
        )}
      </HeaderOverlay>
    </Overlay>
  );
}

export default OpenBudget;
