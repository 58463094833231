import { combineReducers } from "@reduxjs/toolkit";
import { reducer as notifierReducer } from "../slices/notifier";
import { reducer as modalsReducer } from "../slices/modals";
import { reducer as authReducer } from "../slices/auth";
import { reducer as layoutReducer } from "../slices/layout";
import { reducer as projectReducer } from "../slices/project";
import { reducer as tableReducer } from "../slices/table";
import { reducer as categoriesReducer } from "../slices/categories";
import { reducer as elementsReducer } from "../slices/elements";
import { reducer as objectivesReducer } from "../slices/objectives";
import { reducer as syntheseReducer } from "../slices/synthese";
import { reducer as accountsReducer } from "../slices/accounts";

const combinedReducer = combineReducers({
  notifier: notifierReducer,
  modals: modalsReducer,
  auth: authReducer,
  layout: layoutReducer,
  project: projectReducer,
  table: tableReducer,
  categories: categoriesReducer,
  elements: elementsReducer,
  objectives: objectivesReducer,
  synthese: syntheseReducer,
  accounts: accountsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
