import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const MenuButton = ({
  id,
  handleSubmit,
  name,
  className,
  primaryIcon,
  secondaryIcon,
  onHoverIn,
  onHoveringOut,
}) => {
  const { mode } = useSelector((state) => state.layout);
  const location = useLocation();

  const [isListing, setIsListing] = useState(
    location.pathname.includes("listing")
  );

  useEffect(() => {
    setIsListing(location.pathname.includes("listing"));
  }, [location.pathname]);

  return (
    <button
      key={id}
      type="button"
      onClick={handleSubmit}
      className={className}
      onMouseOver={onHoverIn}
      onMouseOut={onHoveringOut}
      style={{
        opacity:
          (name === "Afficher un budget prev." && mode === "treso") ||
          ([
            "Afficher un budget prev.",
            "Afficher une synthèse du résultat ",
            "Afficher une synthèse de trésorerie",
          ].includes(name) &&
            isListing)
            ? "0.5"
            : "1",
      }}
    >
      <img className="nav-img" src={primaryIcon} />
      <span className="hidden-element">{name}</span>
      <img className="nav-img" src={secondaryIcon} id="arrow" />
    </button>
  );
};
export default MenuButton;
