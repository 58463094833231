import { message } from "antd";
import React from "react";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

import { useDispatch } from "react-redux";

import {
  createCategory,
  deleteInput,
  editCategory,
} from "../../../data/slices/categories";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";

function CreateCategoryCheck({ open, handleClose, id, data }) {
  const handleCloseFunc = (id) => {
    handleClose(id);
  };

  const dispatch = useDispatch();

  const handleConfirm = async () => {
    try {
      const result = await data.action();
      if (
        editCategory.rejected.match(result) ||
        createCategory.rejected.match(result)
      ) {
        displayValidationErrors(result.payload);
      } else if (
        editCategory.fulfilled.match(result) ||
        createCategory.fulfilled.match(result)
      ) {
        sessionStorage.removeItem("category");
        dispatch(deleteInput());
        data.abandon();
        message.success(result.payload?.message || "success");
        handleCloseFunc(id);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Overlay show={open} closable={true} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={
            <div style={{ width: "95%" }}>
              Cette modification de l’organisation de vos catégories engendrera
              des modifications sur vos budgets prévisionnels enregistrés et
              pourra y générer des alertes.
            </div>
          }
          subTitle={`Vous allez ajouter une catégorie`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Annuler"}
            onClick={() => {
              handleClose(id);
              data.abandon();
            }}
            className={"action-button"}
          />
          <Button
            text={"Continuer"}
            onClick={() => handleConfirm()}
            className={"action-button agree-action-button"}
            iconClassName={"delete-icon"}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default CreateCategoryCheck;
