import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import localeFr from "antd/es/date-picker/locale/fr_FR";
import calendarInput from "../../assets/img/CALENDRIER.svg";
import { formatDateString } from "../../utilities/filters";
const CustomDatePicker = ({
  setFieldValue,
  handleBlur,
  onFocus,
  label,
  name,
  isTreso,
  values,
  style,
  errors,
  touched,
}) => {
  const error = errors[name];

  const touch = touched[name];

  const formatToUse = isTreso
    ? ["MM/YYYY", "MM.YYYY", "MM-YYYY"]
    : ["DD/MM/YYYY", "DD.MM.YYYY", "DD-MM-YYYY"];

  const inputType = isTreso ? "month" : "";

  return (
    <div className="wrapper-select">
      <>
        <label htmlFor={label} className="input-text">
          {label}
        </label>
        <DatePicker
          style={style}
          allowClear={false}
          id={name}
          picker={inputType}
          name={name}
          defaultValue={values ? dayjs(values) : null}
          onFocus={onFocus}
          superNextIcon={null}
          superPrevIcon={null}
          format={formatToUse}
          value={values ? dayjs(values) : null}
          placeholder="Choisir une date"
          locale={localeFr}
          className={
            values
              ? "form-element-input small"
              : "form-element-input calendar not-filled"
          }
          onBlur={handleBlur}
          dropdownClassName="custom-datepicker-dropdown"
          suffixIcon={<img src={calendarInput} alt="CalendarIcon" />}
          size="large"
          onChange={(dateString) => {
            setFieldValue(name, formatDateString(dateString));
          }}
        />
        {error && touch ? (
          <div className="error-displayer-form-modal">{error}</div>
        ) : null}
      </>
    </div>
  );
};

export default CustomDatePicker;
