import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../data/slices/modals";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";
import { openReellePopup } from "../../../data/slices/elements";

function ModifySerie({ open, id, data, handleClose }) {
  const dispatch = useDispatch();

  const handleOpenClose = () => {
    handleClose(id);
  };

  const handleConfirmEditSerie = async (serieToEdit) => {
    handleOpenClose();
    dispatch(openReellePopup(serieToEdit?.categoryToSend));
    dispatch(
      openModal("add-writing-expression", { ...serieToEdit, type: "all" })
    );
  };

  const handleConfirmEditDuplicatedElement = async (
    duplicatedElementToEdit
  ) => {
    handleOpenClose();
    dispatch(openReellePopup(duplicatedElementToEdit?.categoryToSend));
    dispatch(
      openModal("add-writing-expression", {
        ...duplicatedElementToEdit,
        type: "one",
      })
    );
  };

  return (
    <Overlay show={open} closable={true} onClick={() => handleClose(id)}>
      <HeaderOverlay showlogo>
        <Title
          onClose={() => handleClose(id)}
          isDelete
          projectTitle={"Vous souhaitez:"}
          subTitle={`Modification sur une série`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={
              "Modifier uniquement cette écriture (elle ne fera plus partie de la série)"
            }
            onClick={() =>
              handleConfirmEditDuplicatedElement(data?.serieToEdit)
            }
            className={"action-button"}
            style={{
              width: "255px",
              height: "42px",
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
            }}
          />
          <Button
            text={"Modifier toute la série"}
            onClick={() => handleConfirmEditSerie(data?.serieToEdit)}
            style={{
              width: "255px",
              backgroundColor: "white",
              color: "black",
              height: "42px",
              border: "1px solid black",
            }}
            className={"action-button"}
          />
        </div>
        <div className="verify-inputs-popup" style={{ marginTop: "35px" }}>
          <Button
            text={"Annuler"}
            onClick={() => handleOpenClose()}
            className={"action-button"}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default ModifySerie;
