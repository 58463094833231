import React from "react";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

function DndCheck({ open, id, handleClose, data }) {
  const handleCloseFunc = (id) => {
    handleClose(id);
    data.abandon();
  };
  return (
    <Overlay show={open} closable={true} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={
            <div style={{ width: "95%" }}>
              Cette modification de l’organisation de vos catégories engendrera
              des modifications sur vos budgets prévisionnels enregistrés et
              pourra y générer des alertes.
            </div>
          }
          subTitle={`Vous avez modifié l’organisation de vos catégories !`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Annuler déplacer la catègorie"}
            onClick={() => handleCloseFunc(id)}
            className={"action-button"}
            style={{ width: "200px" }}
          />
          <Button
            text={"ok merci pour l'info !"}
            onClick={() => data.action()}
            className={"action-button agree-action-button"}
            iconClassName={"delete-icon"}
            style={{ width: "200px" }}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default DndCheck;
