import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../data/slices/modals";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

function ModifyBudget({ open, id, data, handleClose }) {
  const dispatch = useDispatch();

  const handleOpenClose = () => {
    handleClose(id);
    dispatch(
      openModal("open-budget-modal", {
        objectif: data.objectif,
      })
    );
  };

  return (
    <Overlay show={open} closable={true} onClick={() => handleClose(id)}>
      <HeaderOverlay showlogo>
        <Title
          hasCloseButton
          onClose={() => handleClose(id)}
          isDelete
          projectTitle={
            "Pour modifier les montants de votre budget vous devez d’abord déverrouiller le budget"
          }
          subTitle={`Modifier Budget`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Déverrouiller le budget"}
            onClick={() => handleOpenClose()}
            className={"action-button"}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default ModifyBudget;
