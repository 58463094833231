import dayjs from "dayjs";
import { parseMonthYear } from "./filters";

const groupElementsByDateRecursively = (category, mode) => {
  const categoryCopy = {
    ...category,
    children: category.children
      ? category.children.map((child) =>
          groupElementsByDateRecursively(child, mode)
        )
      : [],
  };

  if (categoryCopy.elements) {
    const elementsByDate = {};

    categoryCopy?.elements?.forEach((element) => {
      const dateMode =
        mode === "treso" ? element.dateTreso : element.dateCompta;
      const date = parseMonthYear(dateMode);
      const key = date.format("MMMM YYYY");

      if (!elementsByDate[key]) {
        elementsByDate[key] = [];
      }
      elementsByDate[key].push(element);
    });

    categoryCopy.elements = elementsByDate;
  }
  return categoryCopy;
};

const groupElementsByDate = (categories, mode) => {
  return categories.map((category) =>
    groupElementsByDateRecursively(category, mode)
  );
};

function makeElementsEmpty(arr) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return arr.map((item) => {
    if (typeof item === "object" && item !== null) {
      const { id, children } = item;
      const updatedItem = {
        id,
        ...item,
        elements: [],
        children: makeElementsEmpty(children),
      };
      return updatedItem;
    } else {
      return {};
    }
  });
}

function formattedElements(elements) {
  return Object.keys(elements).reduce((result, key) => {
    const [year, month] = key.split("-");
    const formattedMonth = dayjs(`${year}-${month}-01`)
      .format("MMMM YYYY")
      .toLowerCase();
    if (!result[formattedMonth]) {
      result[formattedMonth] = [];
    }
    elements[key].forEach((item) => {
      result[formattedMonth].push({ ...item });
    });
    return result;
  }, {});
}

export { formattedElements, groupElementsByDate, makeElementsEmpty };
